import React, { useState, useEffect } from 'react';
import axios from 'axios';

import LoadingView from 'views/loading';

import GunbotStorage from 'assets/js/gunbot.storage';

export default function StandaloneProvider({ children }) {
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    axios.get('/config').then(res => {
      GunbotStorage.set('app-config', res.data);
    }).finally(() => {
      setChecking(false);
    })
  }, []);

  if (checking) {
    return <LoadingView />;
  }

  return children;
}
