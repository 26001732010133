import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Web3ReactProvider } from '@web3-react/core';
import Web3ManageProvider from 'providers/Web3ManageProvider';
import ConfirmDialogProvider from 'providers/ConfirmDialogProvider';
import StepGuideProvider from 'providers/StepGuideProvider';
import StartupGunbotSetupProvider from 'views/StartupGunbotSetup/StartupGunbotSetupProvider';
import { CustomSnackbarProvider } from 'assets/js/notificationStyles';
import theme from 'assets/js/theme';
import store from 'redux/store';
import { Web3Provider } from '@ethersproject/providers';
import StandaloneProvider from './StandaloneProvider';

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const AppProviders = ({ children }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmDialogProvider>
        <CustomSnackbarProvider
          preventDuplicate
          hideIconVariant
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          dense
          options={{
            autoHideDuration: 7000,
          }}
        >
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ManageProvider>
              <StepGuideProvider>
                <StandaloneProvider>
                  <StartupGunbotSetupProvider>{children}</StartupGunbotSetupProvider>
                </StandaloneProvider>
              </StepGuideProvider>
            </Web3ManageProvider>
          </Web3ReactProvider>
        </CustomSnackbarProvider>
      </ConfirmDialogProvider>
    </ThemeProvider>
  </Provider>
);

export default AppProviders;